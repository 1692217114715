import React, { useState } from 'react'
import { title, subtitle, images } from './data.json'

import Thumbnail from './components/Thumbnail'
import Details from './components/Details'

import './App.css';

function App() {
  const [view, setView] = useState('gallery')
  const [active, setActive] = useState('')

  const onThumbnailClick = (title, details) => {
    if (details.detailImages.length > 0){
      setView('detail')
      setActive(title)
    }
    
  }


  const onDetailsBackButtonClick = () =>{
    setView('gallery')
    setActive('')
  }
  return (
    <div className="App">
      <header>
        <h1>{title}</h1>
        <h2>{subtitle}</h2>
        <div className="navigation">
          <div className={view === 'gallery' ? 'active' : null} onClick={() => onDetailsBackButtonClick()}>Gallery</div>
          <div onClick={() => window.open('https://jmwatercolor.artspan.com/carousel.php?galleryID=290005')}>Shop</div>
          </div>
      </header>
      <main>
        {view === 'gallery' && (
          <div className="gallery">
            {images.map(({ source, title, details }) => (
              <div key={title} onClick={() => onThumbnailClick(title, details)}><Thumbnail key={title} src={source} title={title} details={details.detailImages.length} /></div>
            ))}
          </div>
        )}
        {view === 'detail' && <Details active={active} backButtonClicked={() => onDetailsBackButtonClick()} />}

      </main>
    </div>
  );
}

export default App;
