import React from 'react';

function Thumbnail({ src, title, details }) {
    return (
        <div className="Thumbnail">
            {details  === 0 && <div className="no-details">Detailed Images Coming Soon</div>}
            <img src={src} alt={title} />
        </div>
    );
}

export default Thumbnail;
