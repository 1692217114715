import React from 'react'
import { images } from '../data.json'

function Details({ active, backButtonClicked }) {

    const imageData = (images.find((imageItem) => imageItem.title === active))
   
    return (
        <div className="Details">
            <button aria-label="back" title="back" className="back-button" onClick={backButtonClicked}></button>

            <h3> {imageData.title}</h3>
            <p>{imageData.details.description}</p>

            <div className="shop-buttons">
                {imageData.details.forSale.map((saleItem) => {
                    return (
                        <button aria-label="buy prints" title="buy prints" className="buy-button" onClick={() => window.open(saleItem.link)}>{saleItem.width + '" x ' + saleItem.height + '" Prints'}</button>
                    )
                })

                }
            </div>
            {(imageData.details.forSale === undefined) && <div className="not-for-sale">Not for sale</div>}

            <div className="detail-images">
                {imageData.details.detailImages.map((image) => (
                    <img key={image} src={image} />
                ))}
            </div>
            {imageData.details.detailImages.length === 0 && <div>Detailed Images Coming Soon</div>}
        </div>
    )
}

export default Details